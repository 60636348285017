import React from "react"
import s from 'styled-components'

import Post from '../components/post'
import Layout from "../components/layout"
import { Text } from "../data/typography"
const yeonwonText = (
  <Text>
    탐라국(耽羅國)은 시조(始祖) 고을나왕(髙乙那王)이 제주도에 세운 고대왕국으로써 삼국시대(三國時代)에는 구한(九韓) 중 네 번째 되는 나라로 불릴만큼 가장 번성 하였으며 섬나라 특유의 독특한 문화를 이룩하였다.<br /><br />

    영주지에 탐라의 개국에 관하여 다음과 같은 기록이 있다.<br />
    『삼신인이 인간세계를 이루고 9백년이 지난 뒤에 인심이 고씨(髙氏)에게 모아지니 임금이 되어 탁라국(乇羅國:耽羅國)을 세웠다. (厥後九伯年之後人心咸歸髙氏以髙爲君國號乇羅 )』<br /><br />

    이같이 탐라국(耽羅國)을 세울 수 있었던 과정을 고찰하여 보면 최초의 사회구성체인 삼성(三姓)의 각 씨족집단은 자연히 가장 우세한 고씨(髙氏)의 씨족사회를 중심으로 뭉쳐지므로 대집단인 부족연맹사회(部族聯盟社會)가 형성되고 부족국가(部族國家)를 이룩하게 되었다<br />
    (나라의 명칭은 처음에는 乇羅,毛羅,瀛州라 하였으며 일반적으로 耽羅라 통칭하고 있다)<br /><br />

    국가란 크고 작음에 관계없이 그 개념(槪念)상 일정한 영토(領土)를 가지고 있고, 그곳에 거주하는 민족(民族) 집단이 구성되어 있고, 그러한 모든 것을 관할하고 통치하는 정치제도(政治制度)가 있어야 성립할 수가 있다.<br /><br />

    탐라(耽羅)의 건국연대에 관하여는 시조(始祖) 고을나왕(髙乙那王)이 인간세계를 이룩한 후 9백년이 지났다고 하였을 뿐 확실한 연대를 알 수 없으나탐라국왕세기(耽羅國王世紀)에 의하면 기원전 2337년 경이라 하였다.<br /><br />

    그러나 탐라의 건국연대가 문헌상 뚜렷이 밝혀진 삼국(三國)의 건국연대 보다 훨씬 앞서 있고 오래된 것으로 보는 이유는 탐라의 건국을 부족국가(部族國家)로서 성립한 시대부터 보는데 비하여 삼국(三國)의 건국은 고대국가(古代國家)로서 성립한 시대부터 보기 때문이라 하겠다. 즉 국가의 발달 단계는 먼저 부족국가(部族國家)가형성되고 이를 기반으로 하여 나중에 형성된 것이 고대국가(古代國家)인 것이다.부족국가(部族國家)의 특색은 부족연맹체(部族聯盟體)로서 부족장의 지위가 독자적이었으며 사회적 형태는 혈연사회(血緣社會)였다고 한다.<br /><br />

    한편 고대국가(古代國家)의 특색은 왕권의 강화와 동시에 세습제도(世襲制度)가 이루어지고 중앙집권 체제가 확립되었다고 한다.<br /><br />

    이러한 국가형태의 변천과정을 통하여 본다면 탐라는 시조(始祖) 고을나왕(髙乙那王)이 나라를 세운 것은 부족국가의 형태로써 그 후 신라초(新羅初) 15세손 고 후(髙厚) 왕에 이르러 고대국가(古代國家)로 발전하게 되었다.<br /><br />
  </Text>
)

const YeonwonPage = () => (
  <Layout pageTitle={'역 사'} pageSubtitle={'탐라국의 연원'}>
    <Post imageUrl={'/연원.jpg'} text={yeonwonText} />
  </Layout>
)

export default YeonwonPage
